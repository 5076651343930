class Neos {

  static _translationCache = {};

  /**
   * Request a synchronous translation by id from the server.
   * 
   * @deprecated Synchronous XMLHttpRequest on the main thread is deprecated because of its detrimental effects to the end user’s experience.
   * @param {string} identifier The translation identifier to fetch the value for
   * @param {string} source The source translation file for looking up the identifier
   * @param {string} packageKey The package key to load translations from
   * @param {string} fallback (optional) The fallback to use if translation could not be loaded
   * @param {string} locale (optional) The target locale identifier (auto-detect if not given)
   * @param {array} args (optional) Numeric array of placeholder values to inject into translation string
   * @param {int} quantity (optional) If set, plural forms are looked up if needed and available
   * @return {string} The result of the translation or the fallback if given and the request failed in any way
   */
  static translateSync(identifier, source, packageKey, fallback, locale, args, quantity) {
    console.warn("DEPRECATION WARNING!\nNeos.translateSync(...) is no longer supported! You should remove any references to this method in favor of Neos.translate(...)");

    if (!isset(identifier, 'string') || !isset(source, 'string') || !isset(packageKey, 'string')) {
      return false;
    }

    var targetUrl = new URL('/_/core/translate', window.location.toString());
    targetUrl.searchParams.append('identifier', identifier);
    targetUrl.searchParams.append('source', source);
    targetUrl.searchParams.append('package', packageKey);

    if (isset(fallback, 'string')) {
      targetUrl.searchParams.append('fallback', fallback);
    }

    if (isset(locale, 'string')) {
      targetUrl.searchParams.append('locale', locale);
    }

    if (isset(args, 'array')) {
      targetUrl.searchParams.append('arguments', JSON.stringify(args));
    }

    if (isset(quantity, 'number')) {
      targetUrl.searchParams.append('quantity', quantity);
    }

    var request = new XMLHttpRequest();
    request.open('GET', targetUrl.toString(), false);
    request.send(null);

    if (request.status !== 200) {
      return false;
    }

    return request.responseText;
  }

  /**
   * Request a translation by id from the server.
   * 
   * @param {function} cb The callback to invoke when the translation is available (receives the result as first parameter on success, the request status as a second and in case of failure the error message as a third)
   * @param {string} identifier The translation identifier to fetch the value for
   * @param {string} source The source translation file for looking up the identifier
   * @param {string} packageKey The package key to load translations from
   * @param {string} fallback (optional) The fallback to use if translation could not be loaded
   * @param {string} locale (optional) The target locale identifier (auto-detect if not given)
   * @param {array} args (optional) Numeric array of placeholder values to inject into translation string
   * @param {int} quantity (optional) If set, plural forms are looked up if needed and available
   * @return {Promise} The ajax request promise
   */
  static translate(cb, identifier, source, packageKey, fallback, locale, args, quantity) {
    if (!isset(identifier, 'string') || !isset(source, 'string') || !isset(packageKey, 'string')) {
      cb && cb(false, 'Missing required arguments!');
      return Promise.reject('Missing required arguments!');
    }

    return new Promise((resolve, reject) => {
      let translationData = {
        'identifier': identifier,
        'source': source,
        'package': packageKey,
        'fallback': fallback,
        'locale': locale,
        'arguments': JSON.stringify(args),
        'quantity': quantity,
      };
      let translationHash = objectHash(translationData);
      
      if (Neos._translationCache.hasOwnProperty(translationHash)) {
        cb && cb(Neos._translationCache[translationHash], 'OK', null);
        resolve(Neos._translationCache[translationHash]);
      } else if (window._Obis_Core_preloadTranslations && !isset(locale, 'string') && !isset(arguments, 'array') && !isset(quantity, 'number')) {
        let translationId = [packageKey, source, identifier].join(':');
        if (window._Obis_Core_preloadTranslations.hasOwnProperty(translationId)) {
          cb && cb((Neos._translationCache[translationHash] = window._Obis_Core_preloadTranslations[translationId]), 'OK', null);
          resolve(Neos._translationCache[translationHash]);
        }
      } else {
        $.ajax({
          method: 'GET',
          url: '/_/core/translate',
          data: translationData,
          success: (data, status, xhr) => {
            cb && cb((Neos._translationCache[translationHash] = data), status, null);
            resolve(Neos._translationCache[translationHash]);
          },
          error: (jqxhr, status, error) => {
            cb && cb(false, status, error);
            reject(status);
          },
        });
      }
    });
  }

  /**
   * Get the current active locale identifier.
   * @return {string} The current active locale identifier
   */
  static getCurrentLocale() {
    return document.documentElement.getAttribute('lang');
  }

  /**
   * Check if the page is loaded in the Neos backend
   * @return {boolean} true if in backend, false if in frontend
   */
  static inBackend() {
    return document.body.classList.contains('neos-backend');
  }

  /**
   * Load a setting from the exposed configuration.
   * @param {string} path (optional) The path of the setting to load, e.g. "path.to.my.setting"
   */
  static getSetting(path = null) {
    if (!window._Obis_Core_exposedConfiguration) {
      return undefined;
    }

    let config = window._Obis_Core_exposedConfiguration;

    if (path !== null) {
      for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) {
        if (typeof config !== "object" || !config.hasOwnProperty(path[i])) {
          return undefined;
        }

        config = config[path[i]];
      }
    }

    return config;
  }
}