class SwipeEvent extends Event {

  constructor(type, eventInitDict) {
    if (!eventInitDict || typeof eventInitDict !== 'object') {
      eventInitDict = { bubbles: true, cancelable: false, };
    } else {
      eventInitDict = Object.assign({}, eventInitDict, { bubbles: true, cancelable: false, });
    }

    super(type, eventInitDict);
    
    this.touchstart = eventInitDict.touchstart || null;
    this.touchend = eventInitDict.touchend || null;
  }

  getTouchStart() {
    return this.touchstart;
  }

  getTouchEnd() {
    return this.touchend;
  }

  getDistance() {
    return {
      x: this.getDistanceX(),
      y: this.getDistanceY(),
    }
  }

  getDistanceX() {
    if (!(this.touchstart && this.touchend)) return undefined;
    return this.touchend.clientX - this.touchstart.clientX;
  }

  getDistanceY() {
    if (!(this.touchstart && this.touchend)) return undefined;
    return this.touchend.clientY - this.touchstart.clientY;
  }
}

(() => {
  function createSwipeEvent(type, start, end = null) {
    return new SwipeEvent('swipe' + type, {
      touchstart: start,
      touchend: end,
    });
  }

  var startTouches;
  document.addEventListener("touchstart", (event) => {
    startTouches = event.touches;
  }, Modernizr.passiveeventlisteners ? { passive: true } : false);

  document.addEventListener("touchend", (event) => {
    if (!startTouches) return;

    for (var i = 0; i < event.changedTouches.length; i++) {
      const touch = event.changedTouches.item(i);
      const initialTouch = startTouches.item(i);

      if (touch.clientX != initialTouch.clientX) {
        touch.target.dispatchEvent(createSwipeEvent('-x', initialTouch, touch));

        if (touch.clientX > initialTouch.clientX) {
          touch.target.dispatchEvent(createSwipeEvent('right', initialTouch, touch));
        } else if (touch.clientX < initialTouch.clientX) {
          touch.target.dispatchEvent(createSwipeEvent('left', initialTouch, touch));
        }
      }

      if (touch.clientY != initialTouch.clientY) {
        touch.target.dispatchEvent(createSwipeEvent('-y', initialTouch, touch));

        if (touch.clientY > initialTouch.clientY) {
          touch.target.dispatchEvent(createSwipeEvent('down', initialTouch, touch));
        } else if (touch.clientY < initialTouch.clientY) {
          touch.target.dispatchEvent(createSwipeEvent('up', initialTouch, touch));
        }
      }
    }
  }, Modernizr.passiveeventlisteners ? { passive: true } : false);

  document.addEventListener("touchcancel", (event) => {
    for (var i = 0; i < event.changedTouches.length; i++) {
      const touch = event.changedTouches.item(i);
      touch.target.dispatchEvent(createSwipeEvent('cancel', touch));
    }
  }, Modernizr.passiveeventlisteners ? { passive: true } : false);

  document.addEventListener("touchmove", (event) => {
    for (var i = 0; i < event.changedTouches.length; i++) {
      const touch = event.changedTouches.item(i);
      const initialTouch = startTouches.item(i);

      touch.target.dispatchEvent(createSwipeEvent('move', initialTouch, touch));

      if (touch.clientX != initialTouch.clientX) {
        touch.target.dispatchEvent(createSwipeEvent('move-x', initialTouch, touch));

        if (touch.clientX > initialTouch.clientX) {
          touch.target.dispatchEvent(createSwipeEvent('moveright', initialTouch, touch));
        } else if (touch.clientX < initialTouch.clientX) {
          touch.target.dispatchEvent(createSwipeEvent('moveleft', initialTouch, touch));
        }
      }

      if (touch.clientY != initialTouch.clientY) {
        touch.target.dispatchEvent(createSwipeEvent('move-y', initialTouch, touch));

        if (touch.clientY > initialTouch.clientY) {
          touch.target.dispatchEvent(createSwipeEvent('movedown', initialTouch, touch));
        } else if (touch.clientY < initialTouch.clientY) {
          touch.target.dispatchEvent(createSwipeEvent('moveup', initialTouch, touch));
        }
      }
    }
  }, Modernizr.passiveeventlisteners ? { passive: true } : false);
})();