function autoTargetLinks(selector = 'body') {
  var protocol = 'http://';
  var sec_protocol = 'https://';
  var tel_protocol = 'tel:';
  var mail_protocol = 'mailto:';
  var host = document.location.hostname;

  $(selector).find('a').filter(function (elem) {
    return !(
      $(elem).parents('.neos-nodetypes-html').length > 0 ||
      $(elem).hasClass('no-auto-target')
    );
  }).each(function _invoke() {
    var href = $(this).attr('href');
    var target = null, classname;

    if (href !== undefined && href !== null && href !== false && href !== '') {
      if (href.startsWith(tel_protocol) || href.startsWith(mail_protocol)) {
        target = '_blank';
        classname = 'type-other';
      } else if (href.startsWith(protocol) || href.startsWith(sec_protocol)) {
        if (href.includes(host)) {
          if (href.includes('_Resources')) {
            target = '_blank';
            classname = 'type-resource';
          } else {
            target = '_self';
            classname = 'type-internal';
          }
        } else {
          target = '_blank';
          classname = 'type-external';
        }
      } else if (href.startsWith('/')) {
        target = '_self';
        classname = 'type-internal';
      } else {
        target = '_blank';
        classname = 'type-external';
      }
    } else {
      classname = 'type-invalid';
    }

    if (target) $(this).attr('target', target);
    if (classname) $(this).toggleClass(classname, true);
  });
}
