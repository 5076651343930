// trigger a resize event on the window once all images loaded to enfore recalculation of positions and sizes
window.addEventListener('all_images_loaded', () => window.preventResizeOnImageLoad || window.dispatchEvent(new UIEvent('resize', {
  'bubbles': false, 
  'cancelable': false
})));

$(function () {
  var counter = 0;

  function incrementCounter() {
    counter++;
    if (counter === document.images.length) {
      window.dispatchEvent(new Event('all_images_loaded'));
    }
  }

  [].forEach.call(document.images, function (img) {
    if (img.complete)
      incrementCounter();
    else
      $(img).one('load', incrementCounter);
  });
});